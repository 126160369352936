<template>
    <div class="wrapper">

        <router-view></router-view>

        <!-- HeaderNavSix section -->
        <HeaderNav></HeaderNav>

        <div class="main-content">

            <!-- Breadcrumb section -->
            <BreadcrumbProductDetail BreadcrumbProductDetailTitle="Blog Detail" breadcrumbName="Blog Detail"></BreadcrumbProductDetail>

        </div>

        <!-- Banner section -->
        <BannerTwo></BannerTwo>

        <!-- Footer section -->
        <FooterSection></FooterSection>

    </div>
</template>

<script>

// import BlogSlider from '@/components/BlogSlider.vue'
import HeaderNav from '@/components/HeaderNav.vue'
import BannerTwo from '@/components/BannerTwo.vue'
import FooterSection from '@/components/FooterSection.vue'
import BreadcrumbProductDetail from '@/components/BreadcrumbProductDetail.vue'
export default {
  components: {
    HeaderNav,
    FooterSection,
    BannerTwo,
    BreadcrumbProductDetail,
  },
  
}

</script>