<template>
    <div class="breadcrumb-area breadcrumb-height" data-bg-image="" :style="{backgroundImage: `url(${ detailBg })`}">
        <div class="container h-100">
            <div class="row h-100">
                <div class="col-lg-12">
                    <div class="breadcrumb-item">
                        <h1 class="breadcrumb-detail">{{product.title}}</h1>
                        <h6 class="breadcrumb-title">{{product.category}}</h6>
                    </div>
                </div>
            </div>
        </div>
        <div class="display-flex row">
            <div class="col-lg-10 col-md-12">
                <div class="row paragraph" ref="productDescription">
                    <p>{{ product.paragraph }}</p>
                    <p v-if="product.paragraphh">{{ product.paragraphh }}</p>
                    <ul>
                        <li style="margin-bottom: 18px;" v-for="(a,index) in paragraph1" :key="index">{{ a }}</li>
                    </ul>
                    <img v-if="product.image1" :src="product.image1" alt="">
                    <p>{{ product.paragraph2 }}</p>
                    <p v-for="(p, index) in product.paragraphFor" :key="index">{{ p }}</p>
                    <img :src="product.image2" alt="">
                </div>
            </div>
        </div>
    </div>
    <video-background
    src="images/banner/banner-section-top.mp4"
    style="width: 100%;"
    object-fit="cover"
    class="hero-area hero-bg hero-style-1 hero-width"
    >
    </video-background>
    <video-background
    src="images/banner/banner-section-bot.mp4"
    style="width: 100%;"
    object-fit="cover"
    class="hero-area hero-bg hero-style-1 hero-width"
    >
    </video-background>
</template>

<script>
import VideoBackground from 'vue-responsive-video-background-player'
import products from '../data/product.json'
export default {
    components: { 
        VideoBackground
    },
    props: ['breadcrumbName', 'breadcrumbTitle', 'breadcrumbDetail'],
    data () {
        return {
            introBg: 'images/breadcrumb/bg/bg.png',
            detailBg: 'images/breadcrumb/bg/detail-bg2.png',
            products,
            slug: this.$route.params.slug,
            paragraph: [],
            descriptionHeight: 0,
        }
    },
    computed: {
        product () {
            return this.products.products.find(product => product.slug === this.slug)
        },
        paragraph1 (){
            if (this.product.paragraph1) {
                this.paragraph = [this.product.paragraph1.one, this.product.paragraph1.two, this.product.paragraph1.three]
                return this.paragraph
            }
        }
    },
    mounted () {
        this.descriptionHeight = this.$refs.productDescription.offsetHeight
    }
}
</script>
<style scoped>
.breadcrumb-area {
    background-color: #0D0D2D;
}
.breadcrumb-title {
    color: #ffffff;
    font-size: 20px;
    line-height: 20px;
    margin-top: 8px;
}
.breadcrumb-detail {
    color: #ffffff;
    font-family: 'Exo 2';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 72px;
    /* or 150% */
    margin-block: 1px;

    text-align: center;
    letter-spacing: 0.03em;
}
.btn.purple-color {
    background-color: transparent;
    color: #000000;
    border-style: solid;
    border-color: rgb(39, 39, 39);
    font-family: "Gordita";
    width: fit-content;
    font-size: 12px;
    border-radius: 6px;
    padding: 20px 20px;
    line-height: 0px;
}

.display-flex {
    height: 0px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-top: -50px;
}

.display-flex div {
    background-color: white;
    padding: 12px 28px;
    z-index: 10;
    border-radius: 12px;
}

.display-flex span {
    padding: 15px 15px;
}

.paragraph {
    font-family: 'Exo 2';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    /* or 160% */

    text-align: justify;
    align-items: center;
    text-indent: 24px;
    white-space: pre-line;
}

.paragraph li{
    list-style-position: inside;
    text-indent: -1em;
    padding-left: 1em;
}

.paragraph img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 600px;
    height: auto;
    margin: 30px auto;
}

.hero-width{
    width: 100%;
    height: 650px !important;
}
@media only screen and (max-width: 991px) {
    .breadcrumb-title {
        font-size: 16px;
    }

    .hero-width{
        width: 100%;
        height: 925px !important;
    }

    .breadcrumb-height {
        height: 550px;
    }

    .display-flex {
        height: 0px;
        width: 90%;
        display: flex;
        margin-top: -40px;
        margin-left: 5%;
        margin-right: 5%;
    }
    
    .display-flex div {
        text-align: center;
        background-color: white;
        padding: 12px 38px;
        z-index: 10;
        border-radius: 12px;
    }
    
    .display-flex img {
        width: 30%;
        padding: 5px 5px;
    }

    .paragraph img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        max-width: 600px;
        height: auto;
        margin: 30px auto;
    }
}
</style>
